@import '../../styles/abstract.scss';

.default {
	width: 100%;

	h2 {
		margin-bottom: 30px;
		@include flexbox();
		@include align-items(center);
		width: 100%;

		@include media-below(md) {
			line-height: 100%;
			margin-bottom: 40px;
			font-size: 24px;
		}

		a {
			display: block;
			margin-left: auto;
			font-size: 15px;
			line-height: 19px;
			font-weight: 400;

			@include media-below(md) {
				span {
					display: none;
				}

				&:before {
					content: attr(data-mobile-txt);
				}
			}

			i {
				margin-left: 5px;
			}
		}
	}

	[class*="carousel_control"] {
		top: calc(35% - 40px)!important;
	}
}
