@import '../styles/abstract.scss';

.default {
	.itemLists {
		min-height: 500px;
		margin-top: 30px;
	}

	[class*="text-box-grid"] {
		margin-bottom: 60px;

		@include media-below(md) {
			margin-bottom: 40px;
		}
	}
}
