@import '../../styles/abstract.scss';

.default {
	width: 100%;
	min-height: 100px;
	overflow: hidden;

	.control {
		position: absolute;
		width: 40px;
		height: 40px;
		@include border-radius(50%);
		box-shadow: 0px 3px 6px #00000029;
		border: 1px solid $white;
		top: calc(50% - 40px);
		z-index: 2;
		background-color: $white;

		@media print {
			display: none;
		}
	}

	.prev {
		left: -20px;

		@include media-below(lg) {
			left: 10px;
		}
	}

	.next {
		right: -20px;

		@include media-below(lg) {
			right: 10px;
		}
	}

	.canvas {
		position: relative;
		@include flexbox();
		@include transition(left, .3s, ease-in-out);
		@include align-items(flex-start);

		iframe {
			max-height: 540px;
			width: 100%!important;

			@include media-below(sm) {
				max-height: 250px;
			}
		}
	}

	&.ready {
		.prev, .next {
			display: block;
		}
	}

	&.leftEnd {
		.prev {
			display: none;
		}
	}

	&.rightEnd {
		.next {
			display: none;
		}
	}
}
