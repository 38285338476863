@import '../../styles/abstract.scss';

.default {
	background: $background-gray;
	padding: 70px 0;

	p {
		width: 90%;

		&:first-of-type {
			margin: 20px 0;
		}

		@include phone() {
			width: 100%;
		}
	}

	article {
		+ article {
			@include media-below(sm) {
				margin-top: 40px;
			}
		}
	}	
}
